<template>
  <module
    ref="module"
    id="topup"
    title-icon="nc-icon nc-refresh-69"
    :title="topupText"
    :use-default-list="false"
  >
    <div slot="global">
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-if="!inprogress">
        <!-- Modal Instruction for TransferWise EUR-->
        <modal modalClasses="modal-lg" :show.sync="transferModal">
          <h4 slot="header" class="title title-up">{{ $t('transferoptions') }}</h4>

          <div class="row">
            <el-table :data="transferOptions">

              <el-table-column :label="txt.options_bank_name">
                <span slot-scope="scope">
                  {{ scope.row.bank_name }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.options_beneficiary">
                <span slot-scope="scope">
                  {{ scope.row.beneficiary }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.options_iban">
                <span slot-scope="scope">
                  {{ scope.row.IBAN }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.options_swift">
                <span slot-scope="scope">
                  {{ scope.row.SWIFT }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.options_address" width="280px">
                <div class="col-md-12" slot-scope="scope">
                  <div v-for="row in scope.row.address" class="row">
                    <span>{{ row }}</span>
                  </div>
                </div>
              </el-table-column>

            </el-table>
          </div>


          <template slot="footer">
            <p-button
              type="success"
              link
              v-on:click="transferModal = false">
              {{ $t('gotit') }}
            </p-button>
          </template>
        </modal>

        <!-- Modal Instruction for TransferWise USD-->
        <modal modalClasses="modal-lg" :show.sync="transferModalUSD">
          <h4 slot="header" class="title title-up">{{ $t('transferoptions') }}</h4>

          <div class="row">
            <el-table :data="transferOptionsUSD" style="width: 100%">

              <el-table-column :label="txt.options_bank_name">
                <span slot-scope="scope">
                  {{ scope.row.bank_name }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.options_accountHolder" min-width="100">
                <span slot-scope="scope">
                  {{ scope.row.beneficiary }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.options_routingNumber" min-width="100">
                <span slot-scope="scope">
                  {{ scope.row.routingNumber }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.options_swiftBic" min-width="100">
                <span slot-scope="scope">
                  {{ scope.row.swiftBic }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.options_accountNumber" min-width="100">
                <span slot-scope="scope">
                  {{ scope.row.accountNumber }}
                </span>
              </el-table-column>

              <el-table-column :label="$t('type')">
                <span slot-scope="scope">
                  {{ scope.row.accountType }}
                </span>
              </el-table-column>


              <el-table-column :label="txt.options_address" width="240">
                <div class="col-md-12" slot-scope="scope">
                  <div v-for="row in scope.row.address" class="row">
                    <span>{{ row }}</span>
                  </div>
                </div>
              </el-table-column>

            </el-table>
          </div>


          <template slot="footer">
            <p-button
              type="success"
              link
              v-on:click="transferModalUSD = false">
              {{ $t('gotit') }}
            </p-button>
          </template>
        </modal>

        <!-- Modal Instruction Modal -->
        <modal :show.sync="instructionModal">
          <h4 slot="header" class="title title-up">{{ $t('transferoptions') }}</h4>
          <div class="row">
            <div class="col-md-12">
              <div class="alert alert-info" v-if="!instructionMethodAlert">
                <i class="fa fa-info-circle"></i>
                {{ $t('methodCharge', [getMethodName(), getMethodCharge()]) }}
              </div>
              <div class="alert alert-danger" v-if="instructionMethodAlert">
                <i class="fa fa-info-circle"></i>
                {{ $t('inserdepositvalue') }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!instructionModalConfirmed">
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ txt.paypal.value }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="height: 40px">
                      HKO
                    </span>
                  </div>
                  <money
                    class="form-control input-sm text-right"
                    style="height: 40px"
                    v-model="exchangeHko"></money>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t('helper_excentry') }}</label>
                <span class="form-control text-right" disabled style="background-color: lightgray">
                        {{ exchangeValue }}
                      </span>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>{{ txt.paymentamount }}</label>
                <span class="form-control text-right">
                    <span> {{ exchange.currency.toUpperCase() }} <i class="fa fa-dollar"></i></span>
                    {{ exchangeValueModal }}
                  </span>
              </div>
            </div>
          </div>

          <div class="row" v-if="instructionModalConfirmed">
            <div class="col-md-12">
              <p>
              <h6>{{ txt.payment_address }}: {{ this.instructionInfo.instructionus }}</h6>
            </div>
          </div>

          <template slot="footer">
            <div class="left-side" v-if="!instructionModalConfirmed">
              <p-button type="success" link v-on:click="confirmInstructionModal()">
                {{ $t('confirm') }}
              </p-button>
            </div>

            <div class="left-side" v-if="instructionModalConfirmed">
              <p-button type="success" link v-on:click="closeInstructionModal()">
                {{ $t('confirm') }}
              </p-button>
            </div>

            <div class="divider"></div>

            <div class="right-side">
              <p-button type="danger" v-on:click="closeInstructionModal()" link>
                {{ $t('cancel') }}
              </p-button>
            </div>
          </template>
        </modal>

        <!-- Modal for New or Update Own Entry -->
        <modal :show.sync="modal" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up" v-if="newentry">{{ $t('newentrytitle') }}</h4>
          <h4 slot="header" class="title title-up" v-else>{{ $t('upentrytitle') }}</h4>
          <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show" v-if="modal_alert">
            <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                    v-on:click="closeModalAlert">
              <i class="nc-icon nc-simple-remove"></i>
            </button>
            <span>{{ modal_alert_msg }}</span>
          </div>


          <div class="row">
            <div class="col-12" v-if="selling">
              <small class="text-muted">{{ $t('helper_maxentry') }}</small>
              <fg-input v-model="myentry.max_sale" addon-left-icon="fa fa-chevron-up" type="number" min="10" step="1"
                        :placeholder="helper_maxentry"></fg-input>
            </div>
            <div class="col-12" v-else>
              <small class="text-muted">{{ $t('helper_minentry') }}</small>
              <fg-input v-model="myentry.min_sale" addon-left-icon="fa fa-chevron-up" type="number" min="10" step="1"
                        :placeholder="helper_minentry"></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mx-auto text-center">
              <div style="display: inline-flex;">
                <div class="info-icon info-l10 info-t5">
                  <el-tooltip :content="exc_consent_txt" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i"></i>
                  </el-tooltip>
                </div>
                <p-checkbox v-model="myentry.public_viewed" :disabled="!newentry">
                    <span class="allupper">
                      {{ $t('listedpublically') }}
                    </span>
                </p-checkbox>
              </div>
            </div>
          </div>

          <template slot="footer">
            <div class="py-3 mx-auto" v-if="modalinprogress">
              <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
            </div>
            <div class="left-side" v-if="modalinprogress == false">
              <p-button @click.prevent="validate" link v-if="newentry" :disabled="myentry.public_viewed == false">
                {{ $t('newentrybtn') }}
              </p-button>
              <p-button @click.prevent="validate" link v-else>{{ $t('upentrybtn') }}</p-button>
            </div>
            <div class="divider" v-if="modalinprogress == false"></div>
            <div class="right-side" v-if="modalinprogress == false">
              <p-button type="danger" v-on:click="closeModalAlert" link>{{ $t('cancel') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <!-- Modal for Delete Entry -->
        <modal :show.sync="modalconfirm" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">{{ $t('delentrytitle') }}</h4>
          <p>
          <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show" v-if="modal_alert">
            <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                    v-on:click="closeModalConfirmAlert">
              <i class="nc-icon nc-simple-remove"></i>
            </button>
            <span>{{ modal_alert_msg }}</span>
          </div>

          <div class="row">
            <div class="col-12">
              {{ $t('confirmdelentry') }}
            </div>
          </div>

          <template slot="footer">
            <div class="py-3 mx-auto" v-if="modalinprogress">
              <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
            </div>
            <div class="left-side" v-if="modalinprogress == false">
              <p-button @click.prevent="confirmDeletion" link>{{ $t('deletevps') }}</p-button>
            </div>
            <div class="divider" v-if="modalinprogress == false"></div>
            <div class="right-side" v-if="modalinprogress == false">
              <p-button type="danger" v-on:click="closeModalConfirmAlert" link>{{ $t('cancel') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <!-- Modal -->
        <modal :show="modalPayPal">
          <template slot="header">
            <h5>{{ $t('makeyourdepositwithpaypal') }}</h5>
          </template>

          <template v-if="paypalModalState == 5" slot="default">
            <h5>{{ $t('sorry') }}</h5>
            <span>{{ $t('paypalwentwrong_internal') }}</span>
          </template>

          <template v-if="paypalModalState == 4" slot="default">
            <h5>{{ $t('sorry') }}</h5>
            <span>{{ $t('paypalwentwrong_external') }}</span>
          </template>

          <template v-if="paypalModalState == 3" slot="default">
            <h5>{{ $t('thankyou') }}</h5>
            <span>{{ $t('paypalrequestconfirmed') }}</span>
          </template>

          <template v-if="paypalModalState == 2" slot="default">
            <div class="text-center">
              <h5>{{ $t('paypalconfirmation') }} </h5>
              <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
            </div>
          </template>

          <template v-if="paypalModalState == 1" slot="default">
            <div class="row">
              <div class="col-md-12">
                <div class="alert alert-info" v-if="!failPayPalValue">
                  <i class="fa fa-info-circle"></i>
                  {{ $t('methodCharge', ['PayPal', getPercentage()]) }}
                </div>
                <div class="alert alert-danger" v-if="failPayPalValue">
                  <i class="fa fa-info-circle"></i>
                  {{ $t('inserdepositvalue') }}
                </div>
              </div>
            </div>

            <div class="row" v-if="!paypalValueConfirmed">

              <div class="col-md-6">
                <div class="form-group">
                  <label>{{ txt.paypal.value }}</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="height: 40px">
                        HKO
                      </span>
                    </div>
                    <money
                      class="form-control input-sm text-right"
                      style="height: 40px"
                      v-model="paypalHko"></money>
                  </div>
                </div>
              </div>

              <div class="col-md-6" v-if="exchange.currency == 'eur'">
                <div class="form-group">
                  <label>{{ $t('helper_excentry') }}</label>
                  <span class="form-control text-right" disabled style="background-color: lightgray">
                      {{ parseFloat(rates.EUR + 0.01).toFixed(2) }}
                    </span>
                </div>
              </div>

              <div class="col-md-6" v-if="exchange.currency == 'usd'">
                <div class="form-group">
                  <label>{{ $t('helper_excentry') }}</label>
                  <span class="form-control text-right" disabled style="background-color: lightgray">1.00</span>
                </div>
              </div>

            </div>

            <div class="row" v-if="exchange.currency == 'usd'">
              <div class="col-md-12">
                <div class="form-group">
                  <label>{{ $t('cost') }}</label>
                  <span class="form-control text-right">
                      <span> USD <i class="fa fa-dollar"></i></span>
                      {{ applyMask(paypalValue) }}
                    </span>
                </div>
              </div>
            </div>

            <div class="row" v-if="exchange.currency == 'eur'">
              <div class="col-md-12">
                <div class="form-group">
                  <label>{{ $t('cost') }}</label>
                  <span class="form-control text-right">
                      <span v-if="exchange.currency == 'usd'">USD <i class="fa fa-dollar"></i></span>
                      <span>EUR <i class="fa fa-euro"></i></span>
                      {{ applyMask(paypalValue) }}
                    </span>
                </div>
              </div>
            </div>

            <div class="row" v-if="paypalValueConfirmed">
              <div class="col-md-12">
                <div id="divpaypal" ref="paypal"></div>
              </div>
            </div>
          </template>

          <template v-if="paypalModalState == 1" slot="footer">

            <div class="left-side" v-if="!paypalValueConfirmed">
              <p-button type="success" link v-on:click="confirmValuePayPal()">
                {{ $t('confirm') }}
              </p-button>
            </div>

            <div class="divider" v-if="!paypalValueConfirmed"></div>

            <div class="right-side" v-if="!paypalValueConfirmed">
              <p-button type="danger" v-on:click="modalPayPal = false" link>
                {{ $t('cancel') }}
              </p-button>
            </div>

            <p-button
              v-if="paypalValueConfirmed"
              type="danger"
              link
              v-on:click="closePayPalLoadedModal()">
              {{ $t('cancel') }}
            </p-button>

          </template>

          <template v-if="paypalModalState == 3" slot="footer">
            <p-button
              type="success"
              link
              v-on:click="modalPayPal = false">
              {{ $t('gotit') }}
            </p-button>
          </template>

          <template v-if="paypalModalState == 4 || paypalModalState == 5" slot="footer">
            <p-button
              type="success"
              link
              v-on:click="modalPayPal = false">
              {{ $t('confirm') }}
            </p-button>
          </template>

        </modal>

        <modal :show="transferSenhorMoney">
          <template slot="header">
            <div class="col-md-12 text-center">
              <img src="/static/img/senhormoney.png" style="transform: scale(0.75)"/>
            </div>
          </template>
          <template>
            <div class="col-md-12">
              <p style="text-align: justify">{{ $t('senhormoneyobs1') }} {{ $t('senhormoneyobs2') }}</p>
              <p class="text-info" style="margin-top: 10px">
                <i class="fa fa-info-circle"></i>
                {{ $t('senhormoneyobs3') }}
              </p>
            </div>
          </template>
          <template slot="footer">
            <div class="left-side">
              <p-button type="info" link v-on:click="senhorMoney()">
                {{ $t('follow_instruction') }}
              </p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="transferSenhorMoney = false" link>
                {{ $t('cancel') }}
              </p-button>
            </div>
          </template>
        </modal>

        <modal
          modalClasses="modal-lg"
          :show="cryptoModal">
          <template slot="header">
            <h6>{{ crypto.crypto }} ({{ crypto.isin }})</h6>
          </template>

          <div class="row">
            <div class="col-md-12">
              <div class="alert alert-danger" style="margin-bottom: 35px">
                <i class="fa fa-warning"></i>
                {{ crypto.attention }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 text-center">
              <img :src="getQR(crypto.isin)" alt="">
            </div>
            <div class="col-md-9">

              <table class="table table-bordered mt-2">
                <tr>
                  <td>
                    <b>{{ $t('address') }}</b>
                  </td>
                  <td style="max-width: 50px; word-wrap: break-word;">
                    {{ crypto.address }}
                  </td>
                </tr>
                <tr v-if="crypto.tag">
                  <td>
                    <b>Destination Tag</b>
                  </td>
                  <td>{{ crypto.tag }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ txt.depositminimum }}</b>
                  </td>
                  <td>{{ crypto.deposit_minimum }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t('paysfixedfee') }}</b>
                  </td>
                  <td>{{ crypto.deposit_fixed_fee }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t('variablefee') }}</b>
                  </td>
                  <td>{{ crypto.deposit_var_fee }}</td>
                </tr>
              </table>

            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-center" style="margin-top: 25px">
              <small>
                {{ crypto.note }}
              </small>
            </div>
          </div>

          <template slot="footer">
            <p-button
              type="success"
              link
              v-on:click="cryptoModal = false">
              {{ $t('gotit') }}
            </p-button>
          </template>
        </modal>

        <div>
          <div class="col-md-12">
            <h5 v-if="exchange.currency == 'pick'">
              <i class="fa fa-info-circle"></i>
              {{ $t('paymentinstructionsinfo') }}
            </h5>

            <div class="row">
              <div class="col-md-12 text-center">
                <button class="btn btn-success" v-on:click="setCurrency('brl')">
                  <i>R$</i>
                  BRL
                </button>
                <button class="btn btn-danger" v-on:click="setCurrency('usd')">
                  <i class="fa fa-usd"></i>
                  USD
                </button>
                <button class="btn btn-primary" v-on:click="setCurrency('eur')">
                  <i class="fa fa-eur"></i>
                  EUR
                </button>
                <button class="btn btn-default" v-on:click="setCurrency('crypto')">
                  <i class="fa fa-bitcoin"></i>
                  Crypto
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">

                <!-- TOP-UP TABLE -->
                <div class="col-md-6 offset-md-3" v-if="exchange.currency == 'brl'">
                  <wallet-order />
                </div>

                <div class="col-md-12" v-if="exchange.currency != 'pick' && exchange.currency != 'crypto' && exchange.currency != 'brl'">

                  <h5 class="text-left">{{ $t('methodsavailable') }}</h5>

                  <el-table
                    :emptyText="txt.emptyrow"
                    :data="mountOptions(tablePaymentInstruction, exchange.currency)"
                    v-show="showPaymentTable"
                  >

                    <el-table-column
                      width="250px"
                      :label="txt.options">
                      <template slot-scope="scope">
                        <div v-if="scope.row.name == 'Neteller'">
                          <button class="btn btn-primary" v-on:click="openInstructionModal(scope.row)"
                                  style="width: 220px">
                            {{ $t('paywith', ['Neteller']) }}
                          </button>
                        </div>
                        <div v-if="scope.row.name == 'Skrill'">
                          <button class="btn btn-primary" v-on:click="openInstructionModal(scope.row)"
                                  style="width: 220px">
                            {{ $t('paywith', ['Skrill']) }}
                          </button>
                        </div>
                        <div v-if="scope.row.name == 'PayPal'">
                          <button class="btn btn-primary" v-on:click="paypalModal()" style="width: 220px">
                            {{ $t('paywithpaypal') }}
                          </button>
                        </div>
                        <div v-if="scope.row.name == 'Wire Transfer' && workingCurrency != 'usd' ">
                          <button class="btn btn-primary" v-on:click="transferModal = true" style="width: 220px">
                            {{ scope.row.name }}
                          </button>
                        </div>

                        <div v-if="scope.row.name == 'Wire Transfer' && workingCurrency == 'usd' ">
                          <button class="btn btn-primary" v-on:click="transferModalUSD = true" style="width: 220px">
                            {{ scope.row.name }}
                          </button>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column :label="txt.details" min-width="250px">
                      <div slot-scope="scope">

                        <span v-if="scope.row.details">
                          {{ scope.row.details }}
                        </span>

                        <span v-if="!scope.row.details && exchange.currency == 'usd' && scope.row.name == 'Wire Transfer' && scope.row.fixedCharge > 0">
                          {{ $t('paysfixedfee') }} {{ scope.row.fixedCharge }} HKO
                        </span>

                        <span v-if="!scope.row.details && exchange.currency == 'usd' && scope.row.name != 'Wire Transfer' && scope.row.fixedCharge > 0">
                          {{ $t('paysfixedfee') }} $ {{ scope.row.fixedCharge }}
                        </span>

                        <span v-if="!scope.row.details && exchange.currency == 'eur' && scope.row.fixedCharge > 0">
                          {{ $t('paysfixedfee') }} € {{ scope.row.fixedCharge }}
                        </span>

                        <span v-if="scope.row.fixedCharge > 0 && scope.row.percentCharge > 0">
                          +
                        </span>

                        <span v-if="!scope.row.details && exchange.currency != 'brl' && scope.row.percentCharge > 0">
                          {{ $t('variablefee') }} {{ scope.row.percentCharge }} %
                        </span>

                        <span v-if="scope.row.fixedCharge == 0 && scope.row.percentCharge == 0">
                          {{ $t('freetxt') }}
                        </span>
                      </div>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="col-md-12" v-if="exchange.currency == 'crypto'">

                  <h5 class="text-left">{{ $t('cryptosavailable', [$t('topuptitle')]) }}</h5>

                  <el-table :data="methods_crypt">

                    <el-table-column :label="'Crypto'" width="180px">
                        <span slot-scope="scope">
                          {{ scope.row.crypto }}
                        </span>
                    </el-table-column>

                    <el-table-column :label="txt.depositminimum">
                        <span slot-scope="scope">
                          {{ scope.row.deposit_minimum }}
                        </span>
                    </el-table-column>

                    <el-table-column :label="txt.fixedfee">
                        <span slot-scope="scope">
                          {{ scope.row.deposit_fixed_fee }}
                        </span>
                    </el-table-column>

                    <el-table-column :label="txt.variablefee">
                        <span slot-scope="scope">
                          {{ scope.row.deposit_var_fee }}
                        </span>
                    </el-table-column>

                    <el-table-column
                      width="150px"
                      :label="txt.options">
                      <template slot-scope="scope">
                        <button class="btn btn-primary" v-on:click="showQRfor(scope.row)">
                          {{ $t('usethis') }}
                        </button>
                      </template>
                    </el-table-column>

                  </el-table>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </module>
</template>
<script>
import Vue from 'vue'
import money from 'v-money'
import VueCookies from 'vue-cookies'
import { Tabs, TabPane, Button, Card, Modal } from 'src/components/UIComponents'
import {Tooltip, Select, Option} from 'element-ui'
import TableFund from 'src/components/Dashboard/Views/Components/TableFund'
import TableWithdrawal from 'src/components/Dashboard/Views/Components/TableWithdrawal'
import Constants from 'src/assets/constants';
import WalletOrder from "@/components/Dashboard/Views/Dashboard/Billing/WalletOrder";

const perRow = 3;
const rules = Constants.methods.getRules();
let user_data = JSON.parse(localStorage.getItem('user-info'));
let entries = [];
let timeoutcount = 1500;

Vue.use(Select)
Vue.use(Option)
Vue.use(money, {precision: 0})

export default {
  components: {
    WalletOrder,
    Card,
    [Button.name]: Button,
    Modal,
    [Tooltip.name]: Tooltip,
    TabPane,
    Tabs,
    TableFund,
    TableWithdrawal,
  },
  data() {
    return {
      broker: user_data.app_metadata.broker,
      workingCurrency: 'usd',
      exchange: {
        currency: "pick",
        currencies: [
          {
            prop: 'pick',
            label: this.$t('selectcurrency')
          },
          {
            prop: 'usd',
            label: "$ USD"
          },
          {
            prop: 'eur',
            label: "€ EUR"
          },
          {
            prop: 'brl',
            label: "R$ BRL"
          },
          {
            prop: 'crypto',
            label: "Crypto"
          }
        ],
        hko: 1.0,
        exchange: 0,
        withdrawal: {
          hko: 1.0,
          exchange: 0
        }
      },
      model: {
        amount: 100,
        method: null,
        instruction: null,
        limit: 0
      },
      paymethods: [
        {'prop': 'wire', 'label': 'Bank Wire', 'fee': 1.5},
        {'prop': 'skrill', 'label': 'Skrill', 'fee': 3},
        {'prop': 'paypal', 'label': 'Paypal', 'fee': 3}
      ],
      inprogress: true,
      inprogresstxt: this.$t('loadingtxt') +" "+ this.$t('topuptitle') +". "+ this.$t('pleasewait') ,
      marketentries: [],
      rowentries: [],
      exc_consent_txt: this.$t('exc_consent_txt'),
      namecol: this.$t('nametxt'),
      modal: false,
      modal_alert: false,
      modal_alert_msg: null,
      modal_alert_type: 'danger',
      modalinprogress: false,
      modalconfirm: false,
      newentry: true,
      transferModal: false,
      transferModalUSD: false,
      transferSenhorMoney: false,
      crypto: {},
      cryptoModal: false,
      helper_descentry: this.$t('helper_descentry'),
      helper_excentry: this.$t('helper_excentry'),
      helper_currentry: this.$t('helper_currentry'),
      helper_minentry: this.$t('helper_minentry'),
      helper_maxentry: this.$t('helper_maxentry'),
      methods_crypt: [
        {
          "crypto": "Bitcoin",
          "isin": "XBT",
          "address": "3JDHhmi4h9W1Ldwc8TqXYqNAjGG6FQdJos",
          "tag": null,
          "deposit_minimum": "0.0001 XBT ",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "100 HKO",
          "withdraw_fixed_fee": "10 HKO",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        },
        {
          "crypto": "Ethereum ERC20",
          "isin": "ETH",
          "address": "0x924c2288307e033998d7e73cc8ecec2ab1826ea8",
          "tag": null,
          "deposit_minimum": "0.005 ETH",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "100 HKO",
          "withdraw_fixed_fee": "15 HKO",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        },
        {
          "crypto": "Ripple",
          "isin": "XRP",
          "address": "rLHzPsX6oXkzU2qL12kHCH8G8cnZv1rBJh",
          "tag": "1111916622",
          "deposit_minimum": "0.0 XRP ",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "100 HKO",
          "withdraw_fixed_fee": "0,50 HKO",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        },
        {
          "crypto": "Cardano",
          "isin": "ADA",
          "address": "addr1qxknl7y2f40udjtc7zc7gwrlwt73au99t4ukj454hsv2pe0rzr27g03klu862usxqsru794d03gzkk8n86ta34n85z0s09wnqk",
          "tag": null,
          "deposit_minimum": "₳1.000000",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "100 HKO",
          "withdraw_fixed_fee": "3,50 HKO",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        },
        {
          "crypto": "USDC ERC20",
          "isin": "USDC",
          "address": "0x924c2288307e033998d7e73cc8ecec2ab1826ea8",
          "tag": null,
          "deposit_minimum": "USDⓒ10.00",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "100 HKO",
          "withdraw_fixed_fee": "2,50 HKO",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        },
        {
          "crypto": "USDT ERC20",
          "isin": "ERC20",
          "address": "0x924c2288307e033998d7e73cc8ecec2ab1826ea8",
          "tag": null,
          "deposit_minimum": "USD₮ 10.00",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "100 HKO",
          "withdraw_fixed_fee": "7,50 HKO",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        },
        {
          "crypto": "USDT TRC20",
          "isin": "TRC20",
          "address": "TUG3qmzHLCpQit66RYQcKrLBD8gBu8ELVF",
          "tag": null,
          "deposit_minimum": "USD₮ 10.00",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "100 HKO",
          "withdraw_fixed_fee": "20 HKO",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        }
      ],
      txt: {
        depositminimum: this.$t('depositminimum'),
        fixedfee: this.$t('paysfixedfee'),
        variablefee: this.$t('variablefee'),
        details: this.$t('detailstxt'),
        options: this.$t('optionstxt'),
        paypal: {
          value: this.$t('deposit_value')
        },
        options_bank_name: this.$t('bankname'),
        options_beneficiary: this.$t('beneficiary'),
        options_iban: this.$t('iban'),
        options_swift: this.$t('swift'),
        options_address: this.$t('address'),
        payment_address: this.$t('address'),
        preferred_currency: this.$t('preferred_currency'),
        percent_charge: "% " + this.$t('charge'),
        fixed_charge: this.$t('fixed_charge'),
        emptyrow: this.$t('norecordsfounded'),
        paymentamount: this.$t('paymentamount'), //TODO: adicionar "Payment Amount"
        methodCharge: this.$t('methodCharge'),
        options_accountHolder: this.$t('accountHolder'),
        options_accountNumber: this.$t('accountNumber'),
        options_accountType: this.$t('accountType'),
        options_routingNumber: this.$t('routingNumber'),
        options_swiftBic: "SWIFT/BIC"
      },
      myentry: {
        exchange: null,
        currency: null,
        description: null,
        min_sale: 10,
        max_sale: 10,
        public_viewed: false,
        type: null
      },
      actionlbl: this.$t('action'),
      msginterest_comment: null,
      helper_descmsg: this.$t('msginterest_description'),
      lister: {
        'userid': null,
        'first_name': null,
        'last_name': null}
      ,
      interester: {
        'userid': null,
        'first_name': null,
        'last_name': null
      },
      perpageTxt: this.$t('perpage'),
      sortbyTxt: this.$t('sortby'),
      colSort: 'exchange',
      type: "credits",
      withcolSort: 'exchange',
      instructionus: this.$t('instructionus'),
      instructioneur: this.$t('instructioneur'),
      cost: this.$t('feetxt'),
      method: this.$t('paymentmethod'),
      /** Withdrawals **/
      withsortby: {
        column: 'fullname',
        columns: [
          {prop: 'fullname', label: this.$t('nametxt')},
          {prop: 'userid', label: this.$t('email')},
          {prop: 'phone', label: this.$t('phone')},
          {prop: 'min_sale', label: this.$t('maxsale')}
        ]
      },
      withpagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      withsearchQuery: '',
      withtableColumns: [
        {prop: 'userid', label: this.$t('email')},
        {prop: 'phone', label: this.$t('phone')},
        // {prop: 'min_sale', label: this.$t('maxsale')}
      ],
      withtableData: [],
      /** Top-Up **/
      sortby: {
        column: 'first_name',
        columns: [
          {prop: 'first_name', label: this.$t('nametxt')},
          {prop: 'userid', label: this.$t('email')},
          {prop: 'phone', label: this.$t('phone')},
          {prop: 'max_sale', label: this.$t('maxsale')}
        ]
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['fullname', 'userid', 'phone'],
      tableColumns: [
        {prop: 'userid', label: this.$t('email')},
        {prop: 'phone', label: this.$t('phone')},
        {prop: 'max_sale', label: this.$t('maxsale')}
      ],
      /** Withdrawal Requests **/
      sortbywith: {
        column: 'tran_id',
        columns: [
          {prop: 'tran_id', label: this.$t('tranid')},
          {prop: 'request_dt', label: this.$t('requestedon')},
          {prop: 'amount_requested', label: this.$t('withdrawal_requested_amt')},
          {prop: 'currency', label: this.$t('currency')},
          {prop: 'payment_method', label: this.$t('paymentmethod')},
          {prop: 'decision_dt', label: this.$t('decidedon')},
          {prop: 'amount_to_paid', label: this.$t('withdrawal_fund_amt')},
          {prop: 'approval_state', label: this.$t('status')}
        ]
      },
      paginationwith: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuerywith: '',
      propsToSearchwith: ['tran_id', 'currency', 'payment_method', 'approval_state'],
      tableColumnswith: [
        //{ prop: 'tran_id', label: this.$t('tranid') },
        {prop: 'request_dt', label: this.$t('requestedon')},
        {prop: 'amount_requested', label: this.$t('withdrawal_requested_amt')},
        {prop: 'currency', label: this.$t('currency')},
        {prop: 'payment_method', label: this.$t('paymentmethod')},
        {prop: 'decision_dt', label: this.$t('decidedon')},
        {prop: 'amount_to_paid', label: this.$t('withdrawal_fund_amt')},
        {prop: 'approval_state', label: this.$t('status')}
      ],
      tableData: [],
      clonedTableData: [],
      clonedWithTableData: [],
      tableWithdrawals: [],
      clonedWithdrawalTableData: [],
      tablePaymentInstruction: [
        {
          id: 10,
          currency: ['USD'],
          name: this.$t('wiretransfer'),
          instructionus: '-',
          instructioneur: '-',
          percentCharge: "0",
          fixedCharge: "4.14",
        },
        {
          id: 7,
          currency: ['EUR'],
          name: this.$t('wiretransfer'),
          instructionus: '-',
          instructioneur: '-',
          percentCharge: "0",
          fixedCharge: 0,
        },
        {
          id: 3,
          currency: ['USD'],
          name: 'PayPal',
          instructionus: this.$t('paypalinstructions'),
          instructioneur: this.$t('paypalinstructions'),
          cost: "CHECK",
          percentCharge: "5.1",
          fixedCharge: "0.30"
        },
        {
          id: 4,
          currency: ['EUR'],
          name: 'PayPal',
          instructionus: this.$t('paypalinstructions'),
          instructioneur: this.$t('paypalinstructions'),
          cost: "CHECK",
          percentCharge: "3.8",
          fixedCharge: "0.35"
        },
        // {
        //   id: 5,
        //   currency: ['USD'],
        //   name: 'Skrill',
        //   instructionus: this.$t('skrillinstructions'),
        //   instructioneur: this.$t('skrillinstructions'),
        //   percentCharge: "4.95",
        //   fixedCharge: 0
        // },
        // {
        //   id: 6,
        //   currency: ['EUR'],
        //   name: 'Skrill',
        //   instructionus: this.$t('skrillinstructions'),
        //   instructioneur: this.$t('skrillinstructions'),
        //   percentCharge: "1.45",
        //   fixedCharge: 0
        // },
        {
          id: 8,
          currency: ['BRL'],
          name: this.$t('ted'),
          instructionus: '-',
          instructioneur: '-',
          percentCharge: "0",
          fixedCharge: 0,
          details: this.$t('thirdpartyoptionprovided'),
        },
        {
          id: 9,
          currency: ['BRL'],
          name: this.$t('pix'),
          instructionus: '-',
          instructioneur: '-',
          percentCharge: "0",
          fixedCharge: 0,
          details: this.$t('thirdpartyoptionprovided'),
        },
        // {
        //   id: 1,
        //   currency: ['USD'],
        //   name: 'Neteller',
        //   instructionus: this.$t('neteller_usinstructions'),
        //   instructioneur: this.$t('neteller_eurinstructions'),
        //   percentCharge: "4.95",
        //   fixedCharge: 0
        // },
        // {
        //   id: 2,
        //   currency: ['EUR'],
        //   name: 'Neteller',
        //   instructionus: this.$t('neteller_usinstructions'),
        //   instructioneur: this.$t('neteller_eurinstructions'),
        //   percentCharge: "1.45",
        //   fixedCharge: 0
        // },
      ],
      myownentry: false,
      adminusr: false,
      isbroker: false,
      rates: {
        EUR: 0.8510, // 1.1750
        BRL: 0,
        USD: 1.01,
        eur: 0.8510, // 1.1750
        brl: 0,
        usd: 1.01
      },
      statsCards: [],
      vpsusers: [],
      selling: true,
      walletbal: 0,
      ueml: null,
      withfail: false,
      typefordel: null,
      transferOptions: [
        {
          "bank_name": "TransferWise",
          "beneficiary": "Caprico Ltd",
          "IBAN": "BE13 9671 1361 3739",
          "SWIFT": "TRWIBEB1XXX",
          "address": ["TransferWise Europe", "SA Avenue Marnix 13-17 1000", "Brussels, Belgium"]
        }
      ],
      transferOptionsUSD: [
        // {
        //   "bank_name": "TransferWise",
        //   "beneficiary": "Caprico Ltd",
        //   "routingNumber": "084009519",
        //   "accountNumber": "9600 0000 0058 3652",
        //   "accountType": "Checking",
        //   "address": ["TransferWise", "19 W 24th Street NY 10010", "New York, United States"],
        //   "cost": "7.50 HKO",
        // },
        {
          "bank_name": "Local ACH",
          "beneficiary": "Caprico Ltd",
          "routingNumber": "026073150",
          "accountNumber": "8312005801",
          "swiftBic": "-",
          "accountType": "Checking",
          "address": ["Wise's address", "30 W. 26th Street, Sixth Floor, New York NY 10010", "New York, United States"],
          "cost": "4.14 HKO",
        },
        {
          "bank_name": "Global - SWIFT",
          "beneficiary": "Caprico Ltd",
          "routingNumber": "026073150",
          "swiftBic": "CMFGUS33",
          "accountNumber": "8312005801",
          "accountType": "Checking",
          "address": ["Wise's address", "30 W. 26th Street, Sixth Floor, New York NY 10010", "New York, United States"],
          "cost": "4.14 HKO",
        },
      ],
      withTableCounter: 0,
      fee_type: null,
      fee_percent: 0,
      funds_received: 0,
      modalPayPal: false,
      paypalValue: 0,
      paypalHko: 0,
      paypalValueConfirmed: false,
      paypalModalState: 1,
      failPayPalValue: false,
      client_id: 'AXil5553QeFifz2yVy7UC3z5MmtRh7waZyheAo0sxfMOzOl6469kCdrDyDVbmein4hVfANlriW0kMXz4',
      instructionModal: false,
      instructionInfo: '',
      exchangeHko: 0,
      exchangeValueModal: 0,
      showPaymentTable: true,
      showMarketplace: true,
      instructionMethodAlert: false,
      instructionModalConfirmed: false,
      exchangeValue: 0
    }
  },
  methods: {
    getQR(isin) {
      return '/static/img/qr/' + isin + '.png';
    },
    setCurrency(currency) {
      this.exchange.currency = currency
      this.tabchanged(currency)
    },
    openInstructionModal(row){
      this.calculateExchange()
      this.instructionInfo = row
      this.instructionMethodAlert = false
      this.instructionModal = true
      this.instructionModalConfirmed = false
    },
    calculateExchange(){
      if (this.exchange.currency == 'eur') {
        this.exchangeValue = parseFloat(parseFloat(this.rates.EUR+0.01).toFixed(2));
      } else if(this.exchange.currency == 'usd') {
        this.exchangeValue = parseFloat(parseFloat(this.rates.USD-0.01)).toFixed(2);
      }
    },
    closeInstructionModal(){
      this.exchangeHko = 0
      this.instructionModal = false
      this.instructionMethodAlert = false
    },
    confirmInstructionModal(){
      if (this.exchangeHko == 0) {
        this.instructionMethodAlert = true
        return
      }
      this.instructionMethodAlert = false
      this.instructionModalConfirmed = true
    },
    showQRfor(crypto) {
      this.crypto = crypto;
      this.cryptoModal = true;
    },
    getMethodName(){
      let name = this.instructionInfo.name
      // console.log
      return [name]
    },
    getMethodCharge(){
      let charge = this.instructionInfo.fixedCharge
      if(charge == 0 )
        charge = ''
      let percentCharge = this.instructionInfo.percentCharge
      return [charge + percentCharge +' %']
    },
    getPercentage() {
      if (this.workingCurrency == 'eur') {
        return ['€0.35 + 3.8 %']
      }
      return ['$0.30 + 5.1 %']
    },
    closePayPalLoadedModal() {
      document.getElementById('divpaypal').innerHTML = ''
      this.modalPayPal = false;
    },
    confirmationHandler(response) {
      if (response.success) {
        this.paypalModalState = 3;
      } else {
        this.paypalModalState = 5;
      }
    },
    errorHandler() {
      this.paypalModalState = 5;
    },
    responseHandler(details) {
      if (details.status == 'VOIDED') {
        this.paypalModalState = 4;
        return;
      }

      this.paypalModalState = 2;
      this.$paypalUserConfirm({
        userid: this.ueml,
        value: this.paypalHko,
        currency: this.workingCurrency,
        exchange_rate: (this.workingCurrency == 'eur' ? parseFloat(this.rates.EUR) : this.rates.USD) + 0.01,
        paypal_response: details,
      }).then(this.confirmationHandler, this.errorHandler)
    },
    setLoaded: function ( params ) {
      if (!document.getElementById('pcc')) {
        return
      }
      window.paypal.Buttons({
        style: {
          layout: 'horizontal',
          size: 'small',
          color: 'gold',
          shape: 'rect',
        },
        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: params.getValue(),
              }
            }],
            application_context: {
              shipping_preference: 'NO_SHIPPING'
            }
          });
        },
        onApprove: function(data, actions) {
          return actions.order.capture()
            .then(params.handler)
        }
      }).render(this.$refs.paypal);
    },
    mountOptions(table, currency) {
      let cur = currency.toUpperCase()
      let auxTable =  table.filter(item => {
        return item.currency.includes(cur);
      });
      this.showPaymentTable = auxTable.length != 0;
      return auxTable;
    },
    loadPayPal() {
      if (document.getElementById('pcc')) {
        document.getElementById('pcc').remove()
      }
      const script = document.createElement("script");
      script.id = 'pcc'
      const currency = this.workingCurrency.toUpperCase();
      script.src = "https://www.paypal.com/sdk/js?currency=" + currency + "&client-id=" + this.client_id;
      script.addEventListener("load", () => {
        this.setLoaded({
          handler: this.responseHandler,
          getValue: () => {
            return parseFloat(this.paypalValue).toFixed(2);
          }
        });
      });
      document.body.appendChild(script);
    },
    confirmValuePayPal() {
      if (this.paypalHko == 0) {
        this.failPayPalValue = true;
        return
      }
      this.paypalValueConfirmed = true
      this.loadPayPal()
    },
    applyMask(value) {
      return (parseFloat(value).toFixed(2)+"").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    paypalModal() {
      // this.$toast.warning('This option is currently not available')
      this.paypalHko = 0;
      this.paypalValue = 0;
      this.paypalValueConfirmed = false;
      this.paypalModalState = 1;
      this.modalPayPal = true;
    },
    cancelWithdrawal(tranid) {
      this.$opWithdrawRequest(this.ueml, tranid, 'reject')
        .then(this.closeModalAlertPostUpdateReq, this.failWith);
    },
    methodChanged() {
      const midx = this.paymethods.findIndex(p => p.prop == this.model.method);
      if (midx >= 0) {
        this.fee_type = this.paymethods[midx].label;
        this.fee_percent = this.paymethods[midx].fee.toFixed(2) +"%";
        if (this.model.amount !== null && this.model.amount !== undefined && !isNaN(this.model.amount) && this.model.amount.toString().length > 0 && parseInt(this.model.amount) > 0) {
          this.funds_received = parseFloat(this.model.amount) * (1 - (parseFloat(this.paymethods[midx].fee/100)));
        }
      }
    },
    onActivatedTab(tab) {
      this.tabchanged(tab.id);
    },
    tabchanged(curr) {
      this.workingCurrency = curr;
      this.exchange.currency = curr;
      var re = new RegExp(curr, 'gi');
      // tables
      // Withdrawals
      this.withtableData = [];
      for(var j=0;j < this.clonedWithTableData.length;j++) {
        if (this.clonedWithTableData[j].currency.match(re) !== null) {
          this.withtableData.push(this.clonedWithTableData[j]);
        }
      }
      this.withTableCounter += 1;

      // Top-Up
      this.tableData = [];
      for(var i=0;i < this.clonedTableData.length;i++) {
        if (this.clonedTableData[i].currency.match(re) !== null) {
          this.tableData.push(this.clonedTableData[i]);
        }
      }

      this.showMarketplace = this.withtableData.length > 0

      if(this.rates.withdrawal){
        this.calcByCurrency();
      }
      this.inprogress = false;//TODO:
    },
    // Fail Withdrawal
    failWith(resp) {
      this.withfail = resp.msg;
      this.modalinprogress = false;
    },
    // Withdraw funcs
    closeModalAlertPostUpdateReq(resp) {
      if (!resp.success) {
        // TODO alert ERROR:WITHDRAW:UPDATE
        return;
      }
      this.modalinprogress = false;
      this.model = {
        amount: 100,
        method: null,
        instruction: null
      };
      // Get User's Withdrawals if any
      this.$getUserWithdrawal(this.ueml)
        .then(this.loadedWithdrawals, this.failop);
    },
    // Withdraw funcs
    closeModalAlertPostInsertReq(resp) {
      if (!resp.success) {
        // TODO alert ERROR:WITHDRAW:REJECT
        return;
      }
      this.modalinprogress = false;
      this.model = {
        amount: 100,
        method: null,
        instruction: null
      };
      // Get User's Withdrawals if any
      this.$getUserWithdrawal(this.ueml)
        .then(this.loadedWithdrawals, this.failop);
    },
    sendreq() {
      this.modal_alert = false;
      this.modalinprogress = true;
      let amt = parseFloat(this.model.amount);
      if (amt !== undefined && !isNaN(amt) && amt > 0) {
        let meth = this.model.method;
        if (meth == 'wire') {
          meth = 'bank wire';
        }
        this.$sendWithdrawRequest({
          userid: this.ueml,
          amount: this.model.amount,
          currency: this.exchange.currency,
          payment_method: meth,
          description: this.model.instruction,
          amount_to_paid: this.funds_received
        }).then(this.closeModalAlertPostInsertReq, this.failWith);
      }
      else {
        this.failop();
      }
    },
    calcByChangeCurrencyWith() {
      if (this.exchange.withdrawal.hko === undefined || this.exchange.withdrawal.hko.length <= 0) {
        this.exchange.withdrawal.hko = 1;
      }
      if (this.exchange.withdrawal.hko > 1) {
        this.calcByCurrencyWith();
      }
      else {
        this.calcByHKOWith();
      }
    },
    calcByCurrencyWith() {
      if (this.exchange.currency === 'brl') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.BRL * .97).toFixed(2);
        this.exchange.withdrawal.exchange = parseFloat(this.exchange.withdrawal.hko) * parseFloat(this.rates.withdrawal.BRL * .97);
      }
      if (this.exchange.currency === 'eur') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.EUR / 1.01).toFixed(2);
        this.exchange.withdrawal.exchange = this.exchange.withdrawal.hko * parseFloat(this.rates.withdrawal.EUR / 1.01);
      }
      if (this.exchange.currency === 'usd') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.USD);
        this.exchange.withdrawal.exchange = this.exchange.withdrawal.hko * this.rates.withdrawal.exchange_curr;
      }

      this.exchange.withdrawal.exchange = this.exchange.withdrawal.exchange.toFixed(2);
    },
    calcByHKOWith() {
      if (this.exchange.currency === 'brl') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.BRL * 0.97).toFixed(2);
        this.exchange.withdrawal.hko = parseFloat(this.exchange.withdrawal.exchange) / parseFloat(this.rates.withdrawal.BRL * .97);
      }
      if (this.exchange.currency === 'eur') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.EUR / 1.01).toFixed(2);
        this.exchange.withdrawal.hko = parseFloat(this.exchange.withdrawal.exchange) / parseFloat(this.rates.withdrawal.EUR / 1.01);
      }
      if (this.exchange.currency === 'usd') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.USD);
        this.exchange.withdrawal.hko = parseFloat(this.exchange.withdrawal.exchange) / parseFloat(this.rates.withdrawal.USD);
      }

      this.exchange.withdrawal.hko = this.exchange.withdrawal.hko.toFixed(2);
    },
    // Top-Up funcs
    calcByChangeCurrency() {
      if (this.exchange.hko === undefined || this.exchange.hko.length <= 0) {
        this.exchange.hko = 1;
      }
      if (this.exchange.hko > 1) {
        this.calcByCurrency();
      }
      else {
        this.calcByHKO();
      }
    },
    calcByCurrency() {
      if (this.exchange.currency === 'pick' || this.exchange.currency === 'crypto') {
        this.exchange.exchange = 0;
        return;
      }
      if (this.exchange.currency === 'brl') {
        this.rates.exchange_curr = parseFloat(this.rates.BRL * 1.04).toFixed(2);
        this.exchange.exchange = parseFloat(this.exchange.hko) * parseFloat(this.rates.BRL * 1.04);
      }
      if (this.exchange.currency === 'eur') {
        this.rates.exchange_curr = parseFloat(this.rates.EUR * 1.01).toFixed(2);
        this.exchange.exchange = this.exchange.hko * parseFloat(this.rates.EUR * 1.01);
      }
      if (this.exchange.currency === 'usd') {
        this.rates.exchange_curr = parseFloat(this.rates.USD);
        this.exchange.exchange = this.exchange.hko * parseFloat(this.rates.USD);
      }

      this.exchange.exchange = this.exchange.exchange.toFixed(2);
    },
    calcByHKO() {
      if (this.exchange.currency === 'pick') {
        this.exchange.hko = 0;
        return;
      }
      if (this.exchange.currency === 'brl') {
        this.rates.exchange_curr = parseFloat(this.rates.BRL * 1.04).toFixed(2);
        this.exchange.hko = parseFloat(this.exchange.exchange) / parseFloat(this.rates.BRL * 1.04);
      }
      if (this.exchange.currency === 'eur') {
        this.rates.exchange_curr = parseFloat(this.rates.EUR * 1.01).toFixed(2);
        this.exchange.hko = parseFloat(this.exchange.exchange) / parseFloat(this.rates.EUR * 1.01);
      }
      if (this.exchange.currency === 'usd') {
        this.rates.exchange_curr = parseFloat(this.rates.USD);
        this.exchange.hko = parseFloat(this.exchange.exchange) / parseFloat(this.rates.USD);
      }
      this.exchange.hko = this.exchange.hko.toFixed(2);
    },
    closeModalAlertPostUpsert() {
      this.modalinprogress = false;
      this.modal = false;
      this.modal_alert = false;
      this.modal_alert_msg = null;
      if (this.adminusr) {
        let user_data = JSON.parse(localStorage.getItem('user-info'));
        this.myentry = {
          exchange: null,
          currency: null,
          description: null,
          min_sale: null,
          max_sale: 10,
          public_viewed: false
        };
      }
      this.selling = true;
      this.myentry.type = null;
      this.loadAll();
    },
    closeModalConfirmAlert() {
      this.modalinprogress = false;
      this.modalconfirm = false;
      this.modal_alert = false;
      this.modal_alert_msg = null;
      if (this.adminusr) {
        let user_data = JSON.parse(localStorage.getItem('user-info'));
        this.myentry = {
          exchange: null,
          currency: null,
          description: null,
          min_sale: null,
          max_sale: 10,
          public_viewed: false
        };
      }
    },
    closeModalAlert() {
      this.modalinprogress = false;
      this.modal = false;
      this.modal_alert = false;
      this.modal_alert_msg = null;
      this.selling = true;
      this.myentry.type = null;
    },
    openModalWith(userid) {
      this.selling = false;
      this.openModal(userid);
    },
    openModal(userid) {
      if (this.adminusr) {
        let uidx = this.tableData.findIndex(t => t.userid == userid);
        if (uidx >= 0) {
          this.newentry = false;
          this.myentry = Object.assign({}, this.tableData[uidx]);
          this.myentry['public_viewed'] = true;
          user_data = { 'email': userid };
        }
      }
      this.modal = true;
    },
    deleteEntry(email, type) {
      this.modalconfirm = true;
      this.typefordel = type;
      if (this.adminusr) {
        user_data = { 'email': email };
      }
    },
    confirmDeletion() {
      this.modalinprogress = true;
      let param = {
        'userid': user_data.email,
        'currency': this.exchange.currency
      }
      this.$delMarketEntry(param).then(this.closeModalConfirmAlert, this.failop).then(this.loadAll, this.failop);
    },
    loadAll() {
      entries = [];
      //this.marketentries = [];
      //this.rowentries = [];
      this.tableData = [];
      this.withtableData = [];

      // Get Marketplace Entries
      this.$getMarketEntries()
        .then(this.loadUsers, this.failop);
    },
    loadUsersWith() {
      let myentry = {
        'add': true,
        'firstname': null,
        'lastname': null,
        'exchange': null,
        'currency': null,
        'mine': true,
        'min_sale': null,
        'max_sale': null,
        'description': this.$t('addmarketentrydescription')
      };
      let uentryidx = entries.findIndex(e => e.userid == user_data.email);
      if (uentryidx >= 0) {
        myentry = entries[uentryidx];
        myentry['firstname'] = user_data['user_metadata']['first_name'];
        myentry['lastname'] = user_data['user_metadata']['last_name'];
        myentry['fullname'] = user_data['user_metadata']['first_name'] +" "+ user_data['user_metadata']['last_name'];
        myentry['mine'] = true;
        myentry['public_viewed'] = true;
        this.myentry = myentry;
        this.newentry = false;
        // Flag
        this.myownentry = true;
        // Insert my own
        this.tableData.push(myentry);
      }

      let startidx = 0;
      let prevrowidx = 0;

      for(var i = 0;i < entries.length;i++) {
        let entry = entries[i];
        if (entry.userid !== user_data.email) {
          // Get User's First/Last Name
          let uidx = this.vpsusers.findIndex(u => u.userid == entry.userid);
          let mentry = entry;
          mentry['mine'] = false;
          if (uidx >= 0) {
            mentry['firstname'] = this.vpsusers[uidx]['first_name'];
            mentry['lastname'] = this.vpsusers[uidx]['last_name'];
            mentry['fullname'] = this.vpsusers[uidx]['first_name'] +" "+ this.vpsusers[uidx]['last_name'];
          }
          else {
            mentry['fullname'] = entry.userid;
          }
          //this.rowentries.push(mentry);
          this.tableData.push(mentry);
        }
      }
      // Sort
      this.clonedTableData = this.tableData.slice(0);
      // Reset it All
      // Get User's Withdrawals if any
      this.$getUserWithdrawal(this.ueml).then(this.loadedWithdrawals, this.failop);
      //this.inprogress = false;
    },
    loadedWithdrawals(resp) {
      this.model.limit = resp.withdrawal_limit.toFixed(2);
      const approved = resp.withdrawals.approved;
      const pending = resp.withdrawals.pending;
      const rejected = resp.withdrawals.rejected;

      this.tableWithdrawals = [];
      this.tableWithdrawals = approved.concat(pending.concat(rejected));
      this.clonedWithdrawalTableData = this.tableWithdrawals.slice(0);

      this.inprogress = false;
    },
    loadUsers(resp) {
      if (!resp.success) {
        // TODO ALERT
        return;
      }
      let data = resp.data;
      entries = data;
      this.clonedWithTableData = data
      this.tabchanged(this.exchange.currency);
    },
    failop (error) {
    },
    validate() {
      this.modal_alert = false;
      this.modalinprogress = true;
      // When success
      const successop = resp => {


        //this.modalinprogress = false;
        this.modal_alert = true;
        this.modal_alert_type = 'success';
        if (this.newentry) {
          this.modal_alert_msg = this.$t('helper_newentry_done');
        } else {
          this.modal_alert_msg = this.$t('helper_upentry_done');
        }
        setTimeout(this.closeModalAlertPostUpsert, timeoutcount);
      };
      // IF Failed
      const failop_xhr = error => {
        this.modalinprogress = false;

        this.modal_alert = true;
        this.modal_alert_type = 'danger';

        if (error.data !== undefined && error.data !== null && error.data.msg !== undefined && error.data.msg !== null) {
          this.modal_alert_msg = error.data.msg;
        }
        else {
          if (this.newentry) {
            this.modal_alert_msg = this.$t('helper_newentry_fail');
          }
          else {
            this.modal_alert_msg = this.$t('helper_upentry_fail');
          }
        }
        // Reset
        this.selling = true;
        this.myentry.type = null;
      };
      // Validation
      //if (this.myentry.exchange === null || this.myentry.currency === null  || this.myentry.description === null  ||
      //    this.myentry.min_sale === null  || this.myentry.max_sale === null) {
      let proceed = false;

      if (this.myentry.public_viewed) {
        if (this.selling && this.myentry.min_sale >= 10) {
          this.myentry.type = 'buy';
          proceed = true;
        }
        else {
          if (this.myentry.max_sale >= 10) {
            this.myentry.type = 'sell';
            proceed = true;
          }
        }
      }

      //if ((this.myentry.max_sale >= 10 || this.myentry.min_sale >= 10) && this.myentry.public_viewed) {
      if (proceed) {
        let exchange_curr = this.rates.exchange_curr;
        if (this.selling) {
          exchange_curr = this.rates.withdrawal.exchange_curr;
        }
        this.myentry.exchange = exchange_curr;
        this.myentry.currency = this.exchange.currency.toUpperCase();
        this.myentry.description = '';

        // DEPRECATED
        this.$postMarketEntry(user_data, this.myentry, this.newentry)
          .then(successop, failop_xhr);
      }
      else {
        this.modal_alert = true;
        this.modal_alert_msg = this.$t('populatefieldbelow');
        this.modalinprogress = false;
      }
    },
    loadProjections(resp) {
      if (!resp.success) {
        // TODO ADD WARNING
        // TODO WARN:LOAD:PROJECTIONS
        return;
      }
      let data = resp.data;

      let suggestedtopup = 0.00;
      let countfol = 0;
      let countnonfol = 0;
      for(var i=0;i < this.baccs.accounts.length;i++) {
        if (this.baccs.accounts[i].follower) {
          suggestedtopup += this.baccs.accounts[i].unbilled;
          countfol += 1;
        } else {
          countnonfol += 1;
        }
      }
      const topup = {
        type: 'danger',
        icon: 'nc-icon nc-money-coins',
        title: this.$t('suggestedtopup'),
        value: parseFloat(suggestedtopup).toFixed(2) +" HKO",
        balance: parseFloat(suggestedtopup),
        footerText: this.$t('suggestedtopup_footer'),
        footerIcon: 'nc-icon nc-alert-circle-i'
      };
      //this.statsCards.push(topup);

      const current = {
        type: 'info',
        icon: 'nc-icon nc-money-coins',
        title: this.$t('currentbalancealt'),
        value: data.balance +" HKO",
        balance: parseFloat(data.balance),
        footerText: this.$t('currentbalancealt_footer'),
        footerIcon: 'nc-icon nc-alert-circle-i'
      };
      this.statsCards.push(current);

      const daily = {
        type: 'primary',
        icon: 'nc-icon nc-money-coins',
        title: this.$t('dailycost'),
        value: data.daily_cost +" HKO",
        balance: parseFloat(data.daily_cost),
        footerText: this.$t('dailycost_footer'),
        footerIcon: 'nc-icon nc-alert-circle-i'
      };
      //this.statsCards.push(daily);
      // Paymnet Instructions
      this.tablePaymentInstruction = [
        {
          id: 1,
          currency: ['USD'],
          name: 'Neteller',
          instructionus: this.$t('neteller_usinstructions'),
          instructioneur: this.$t('neteller_eurinstructions'),
          percentCharge: "0",
          fixedCharge: 0
        },
        {
          id: 2,
          currency: ['EUR'],
          name: 'Neteller',
          instructionus: this.$t('neteller_usinstructions'),
          instructioneur: this.$t('neteller_eurinstructions'),
          percentCharge: "3.5",
          fixedCharge: 0
        },
        {
          id: 3,
          currency: ['USD'],
          name: 'PayPal',
          instructionus: this.$t('paypalinstructions'),
          instructioneur: this.$t('paypalinstructions'),
          cost: "CHECK",
          percentCharge: "5.1",
          fixedCharge: "0.30"
        },
        {
          id: 4,
          currency: ['EUR'],
          name: 'PayPal',
          instructionus: this.$t('paypalinstructions'),
          instructioneur: this.$t('paypalinstructions'),
          cost: "CHECK",
          percentCharge: "3.8",
          fixedCharge: "0.35"
        },
        {
          id: 5,
          currency: ['USD'],
          name: 'Skrill',
          instructionus: this.$t('skrillinstructions'),
          instructioneur: this.$t('skrillinstructions'),
          percentCharge: "3.5",
          fixedCharge: 0
        },
        {
          id: 6,
          currency: ['EUR'],
          name: 'Skrill',
          instructionus: this.$t('skrillinstructions'),
          instructioneur: this.$t('skrillinstructions'),
          percentCharge: "0",
          fixedCharge: 0
        },
        {
          id: 7,
          currency: ['EUR'],
          name: this.$t('wiretransfer'),
          instructionus: '-',
          instructioneur: '-',
          percentCharge: "0",
          fixedCharge: 0,
        },
      ]
      this.inprogress = false;
    },
    rateLoaded(resp) {
      if (resp.success) {
        this.rates = Object.assign({}, resp.data);
        this.rates.USD = 1.01;
        this.rates.withdrawal = Object.assign({}, resp.data);
        this.rates.withdrawal.USD = 0.99;
        this.calcByCurrency();
      }
      if (this.isbroker) {
        // DISABLED FOR NOW
        this.baccs = {
          accounts: []
        }
        this.$getWalletProjection(user_data.email)
          .then(this.loadProjections, this.loadFail);
      }
    },
    senhorMoney() {
      this.transferSenhorMoney = false;
      window.open('https://senhormoney.com/gestao-de-usuario/entrar/','_blank');
    }
  },
  computed:  {
    topupText:  {
      get() {
        if (this.exchange.currency == 'pick') {
          return this.$t('topuptitle')
        } else {
          return this.$t('topuptitle') + ' (' + (this.exchange.currency).toUpperCase() + ') ';
        }
      }
    }
  },
  beforeMount() {
    if (user_data['roles'].indexOf('admin') >= 0) {
      this.adminusr = true;
    }
    if (user_data['roles'].indexOf('broker') >= 0) {
      this.isbroker = true;
      this.exchange.currencies.pop();
    }
  },
  mounted() {
    if (rules.removeIfUserHas(['Neteller'])) {
      this.tablePaymentInstruction = this.tablePaymentInstruction.filter(item => {
        return item.name != 'Neteller' && item.name != 'Skrill';
      });
      this.paymethods = this.paymethods.filter(item => {
        return item.prop != 'neteller' && item.prop != 'skrill';
      });
    }
    if (!this.isbroker) {
      this.loadAll();
    }
    this.ueml = user_data.email;
    // Wallet Balance
    this.walletbal = VueCookies.get('dash-wallet');
    //this.loadAll();

    this.$getExchangeRates().then(this.rateLoaded, this.failop);

  },
  created(){
    this.$setTranslatingInProgress()
  },
  beforeDestroy(){
    this.$root.$off("showTranslatingProgress")
  },
  watch: {
    'paypalValue': function (newVal) {
      this.$root.$emit('paypalUpdate', newVal);
    },
    'paypalHko': function (newVal) {
      if (newVal > 0) {
        this.failPayPalValue = false;
      }
      if (this.workingCurrency == 'eur') {
        let roundRate = parseFloat(parseFloat(this.rates.EUR+0.01).toFixed(2));
        let paypalMethodCharge = 0.038
        let fixedFee = 0.35
        let aux = newVal * roundRate
        newVal = (( aux + fixedFee) + (aux * paypalMethodCharge)).toFixed(2)
      }
      if (this.workingCurrency == 'usd') {
        let aux = 0.051
        newVal = ((newVal + 0.3) + (newVal * aux )).toFixed(2);
      }
      this.paypalValue = newVal;
    },
    'exchangeHko': function (newVal){
      if (this.workingCurrency == 'eur') {
        let roundRate = parseFloat(parseFloat(this.rates.EUR+0.01).toFixed(2));
        let aux = this.instructionInfo.percentCharge / 100
        let aux2 = newVal * roundRate
        newVal = (aux2 + (aux2 * aux)).toFixed(2)
      }
      if(this.workingCurrency == 'usd'){
        let aux = this.instructionInfo.percentCharge / 100
        newVal = (newVal + (newVal * aux )).toFixed(2);
      }
      this.exchangeValueModal = newVal
    }
  }
}
</script>
<style lang="scss">
.col-adjust{
  padding-left: 0;
}
.adjust-width {
  width: 325px;
}
.move-up-abit {
  margin: 0;
}
.table-responsive {
  overflow: hidden;
}
.allupper {
  text-transform: uppercase;
}
.left-pane {
  border-right: 1px solid gray;
}
.move-center {
  position: relative;
  top: 10px;
}
.modal-lg {
  @media (min-width: 300px) {
    max-width: 350px !important;
    width: 350px !important;
  }
  @media (min-width: 600px) {
    max-width: 640px !important;
    width: 640px !important;
  }
  @media (min-width: 900px) {
    max-width: 768px !important;
    width: 768px !important;
  }
  @media (min-width: 1200px) {
    max-width: 1200px !important;
    width: 1200px !important;
  }
}
</style>
