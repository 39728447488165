<template>
  <div class="wallet-order form-group mt-4">
    <div class="row">
      <div class="alert alert-info col-md-10 offset-md-1">{{ $t('currency_exchange_alert') }}</div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>{{ $t('paymentmethod') }}</label>
        <el-select
          v-loading.fullscreen.lock="loading"
          v-model="model.method"
          placeholder="Select a provider for your top up"
          @change="onMethodSelected"
        >
          <el-option
            v-for="option in methods"
            :key="option.value"
            :label="option.label"
            :value="option"
          ></el-option>
        </el-select>
      </div>
      <div v-if="fees" class="col-md-12 mt-4">
        <div class="alert alert-primary mb-0 text-center">
          {{ fees }}
        </div>
      </div>
      <div class="col-md-6 mt-4">
        <label>{{ $t('topuptxt') }}</label>
        <fg-numeric
          v-model="model.value"
          @change="calcTaxes"
          :step="0.50"
        ></fg-numeric>
      </div>
      <div class="col-md-6 mt-4">
        <label>{{ $t('amount_charged') }}</label>
        <fg-input
         disabled
         class="input-sm"
         v-model="model.charge"
         :addon-left="symbol"
        ></fg-input>
      </div>
      <div class="col-md-12 text-center">
        <p-button type="primary" :disabled="canSubmit" @click="submit">{{ $t('submit') }}</p-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'src/components/UIComponents'
import Constants from "@/assets/constants";
import { getCurrency } from "@/util/CurrencyUtils";

const rules = Constants.methods.getRules();
const user_data = JSON.parse(localStorage.getItem('user-info'));

const smIntegrationConfig = Constants.methods.smIntegrationConf();

export default {
  name: "wallet-order",
  components: {
    Modal,
  },
  computed: {
    canSubmit() {
      return this.model.charge <= 0;
    },
    symbol() {
      return getCurrency(this.model && this.model.method ? (this.model.method.currency || '').toUpperCase() : '');
    },
  },
  data() {
    return {
      methods: [],
      defaultModel: {
        type: 'deposit',
        method: {},
        value: 0,
        exchange_rate: 0,
        charge: 0,
        userid: user_data.email,
        integration_data: {},
        order: {},
      },
      model: {},
      fees: '',
      loading: false,
    }
  },
  methods: {
    closeModal(navigate=false) {
      if(navigate) {
        this.$router.push({ name:'wallet_order_title', params: {order: this.model.order}});
        // this.$router.push({ name:'wallet_order_title'});
      }
      this.model = {...this.defaultModel};
    },
    async onMethodSelected() {
      await this.calcTaxes();
      const method = this.model.method;
      if (method.value) {
        const fixedTxt = `${this.$t('paysfixedfee')} $${method.fixed_fee}`;
        const percentTxt = `${this.$t('variablefee')} ${method.percent_fee}%`;
        const currencyTxt = `${this.$t('helper_excentry')} ${this.symbol}${this.model.exchange_rate}`;
        this.fees = `${fixedTxt} + ${percentTxt} * ${currencyTxt}`;
      } else {
        this.fees = '';
      }
    },
    async calcTaxes() {
      this.model.exchange_rate = await this.getExchangeRate(this.model.value);
      if (this.model.value && this.model.method.value) {
        this.model.charge = parseFloat(
          (this.model.value * this.model.exchange_rate) +
          (
            this.model.method.fixed_fee * this.model.exchange_rate +
            this.model.value * this.model.method.percent_fee / 100 * this.model.exchange_rate
          )
        ).toFixed(2);
      } else {
        this.model.charge = parseFloat(0).toFixed(2);
      }
    },
    async handleSrMoneyAuth() {
      this.loading = true;
      const authPayload = {
        response_type: 'code',
        client_id: smIntegrationConfig.cid,
        state: new Date().getTime(),
      };
      window.onmessage = async (event) => {
        if (!event.data || (event.source !== authWin)) {
          return;
        }
        const code = event.data;
        if(!code) {
          this.$toast.warning(this.$t('operation_not_authorized'));
        } else {
          // console.log('auth_code', code)
          this.model.integration_data.code = code;
          await this.submitOrder();
        }
        window.onmessage = undefined;
        this.loading = false;
      }
      const authWin = this.$openPopUp(
        `${smIntegrationConfig.url}/oauthSM/authorize?${this.$serializeToQueryString(authPayload)}`,
        1024,
        720
      );
      let timer = setInterval(() => {
        if(authWin.closed) {
          clearInterval(timer);
          if (!this.model.integration_data.code) {
            this.$toast.warning(this.$t('operation_not_authorized'));
            window.onmessage = undefined;
            this.loading = false;
          }
        }
      }, 1000);
    },
    async submitOrder() {
      this.loading = true;
      const response = await this.$createWalletOrder({
        ...this.model,
        method_id: this.model.method.id,
        integration_data: JSON.stringify(this.model.integration_data),
      }).then(response => {
        if(!this.$validateResponse(response)) {
          return;
        }
        this.model.order = response.data;
        this.loading = false;
        this.closeModal(true);
      }).catch(err => {
        this.$validateResponse(err.data);
        this.loading = false;
      });
    },
    async submit() {
      if (this.model.method.value === 'sm') {
        await this.handleSrMoneyAuth();
      } else {
        await this.submitOrder();
      }
    },
    async getExchangeRate(value) {
      if (!this.model.method || this.model.method.currency === 'usd') {
        return parseFloat(1).toFixed(4);
      }
      const response = await this.$getWalletExchange(value);
      return parseFloat(response.data || 1).toFixed(4);
    },
  },
  async created(){
    this.defaultModel.exchange_rate = await this.getExchangeRate(0);
    this.methods = (await this.$getWalletMethods()).data || [];
    this.model = {...this.defaultModel};
  },
}
</script>

<style scoped>

</style>
